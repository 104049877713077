const API_URL = 'http://localhost:3000/';
// const API_URL = 'https://forthsoft.com';
const SERVER_ENDPOINT = 'https://forthsoft.hordecall.net/api/admin';

export const API_ROUTES = {
    SIGN_IN:                `${SERVER_ENDPOINT}/login`,
    VIEW_CP:                `${SERVER_ENDPOINT}/view/cp`,
    CREATE_CP:              `${SERVER_ENDPOINT}/create/cp`,
    EDIT_CP:                `${SERVER_ENDPOINT}/edit/cp`,
    DEACTIVATE_CP:          `${SERVER_ENDPOINT}/deactivate/cp`,
    ACTIVATE_CP:            `${SERVER_ENDPOINT}/activate/cp`,
    SEARCH_CP:              `${SERVER_ENDPOINT}/search/cp`,
    VIEW_CP_SERVICES:       `${SERVER_ENDPOINT}/view/cp/service`,
    CREATE_CP_SERVICES:     `${SERVER_ENDPOINT}/create/cp/service`,
    EDIT_CP_SERVICES:       `${SERVER_ENDPOINT}/edit/cp/service`,
    DELETE_CP_SERVICES:     `${SERVER_ENDPOINT}/delete/cp/service`,
    NETWORKS:               `${SERVER_ENDPOINT}/networks`,
    TOTAL_REVENUE:          `${SERVER_ENDPOINT}/cp/totalRevenue`,
    UNSUBSCRIPTION:         `${SERVER_ENDPOINT}/cp/unsubscription`,
    SUBSCRIPTION:           `${SERVER_ENDPOINT}/cp/subscription`,
    STATISTICS:             `${SERVER_ENDPOINT}/dashboard/counts`,
    CREATE_USER:            `${SERVER_ENDPOINT}/create`,
    VIEW_USERS:             `${SERVER_ENDPOINT}/view`,

    SERVER_ENDPOINT,
}