import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import '../../../libs/css/dataTable.css';
import Nulls from '../Nulls';
import FilterComponent from './FilterComponent';
import { format } from 'date-fns';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import { API_ROUTES } from '../../../utils/constants';


function RevenuesTable(props) {
    const { columns, dataRevenue, setPage, isFade, handleDuration, handleOthers, registerName, clearInputs, isAuthExpired, cpSelected, allCPs } = props;

    

    return (
        <div className="App">
            <>
                <FilterComponent handleDuration={handleDuration} handleOthers={handleOthers} registerName={registerName} clearInputs={clearInputs} allCPs={allCPs} />

                <div style={{ opacity: isFade ? '0.3' : 1 }} >
                    <div className='text-left pl-5 mt-15 mb-5 text-black'>Default CP: {cpSelected || "All"}</div>

                    <DataTable
                        className="unsub_datatable users"
                        title=""
                        columns={columns}
                        data={dataRevenue}
                        highlightOnHover
                        onChangePage={page => setPage(page)}
                    />
                </div>
            </>
        </div>
    );
}

export default RevenuesTable;
