import React, { useEffect, useRef, useState } from "react";
import Loaders from "./Loaders";
import HeaderTop from "./HeaderTop";
import Menus from "./Menus";
import Footers from "./Footers";
import Swal from "sweetalert2";
import '../../libs/css/bootstrap.min3.3.7.css';
import '../../libs/css/sweetalert2.min.css';
import { headers, headers1, headers2 } from "../../server_apis/server";
import { API_ROUTES } from "../../utils/constants";
import { useForm } from "react-hook-form";
import { confirmPasswordValidation, emailValidation, passwordValidation } from "../../utils/Input";

import MyTabs from "./MyTabs";


export default function Settings(props){
    const {objectToFormData, errorFunction, Helmet, capitalizeWords, isAuthExpired} = props;

    useEffect(() => {document.title = "Settings | Forthsoft"}, []);

    useEffect(() => {
        const loadScript = (src) => {
          return new Promise((resolve, reject) => {
            const script = document.createElement('script');
            script.src = src;
            script.async = true;
            script.onload = resolve;
            script.onerror = reject;
            document.body.appendChild(script);
          });
        };
        Promise.all([
        //   loadScript('../assets/libs_/js/jquery.min.js'),
          loadScript('../assets/libs_/js/scripts2.js'),
        ])
          .then(() => {
            // Both scripts are loaded
          })
          .catch((error) => {
            console.error('Error loading scripts:', error);
          });
        return () => {
          // Remove the scripts here if necessary
        };
    }, []);
      



    const userData = JSON.parse(localStorage.getItem('userDataFs'));

    const { register: register1, getValues: getValues1, reset: reset1, handleSubmit: handleSubmit1, formState: {errors: errors1, isSubmitting: isSubmitting1, isValid: isValid1} } = useForm({mode: 'onChange'});
    const { register: register2, reset: reset2, handleSubmit: handleSubmit2, formState: {errors: errors2, isSubmitting: isSubmitting2, isValid: isValid2} } = useForm({mode: 'onChange'});
    
    const api_key = userData?.responseDetails?.apiKey ? 'API Key is hidden' : 'API output will show here'
    const [showText, setShowText] = useState('Change Password');
    const [apiKeyText, setApiKeyText] = useState(api_key);
    const [showAPIKey, setShowAPIKey] = useState(true);
    const [loanBtn, setLoanBtn] = useState(false);
    const [storedTwoFA, setStoredTwoFA] = useState(userData?.responseDetails?.twoFa_status);

    


    const enable2FA = async () => {
        //storedTwoFA = 1 is on, 0 is off

        const captions = storedTwoFA === 1 ? "disable" : "enable";
        const captions1 = storedTwoFA !== 1 ? "activated" : "disabled";
        try {
            const result = await Swal.fire({
                title: `Proceed to ${captions} your 2FA?`,
                html: '<div class="mb-10">This can always be undone, proceed?</div>',
                icon: 'question',
                iconHtml: '?',
                showCancelButton: true,
                confirmButtonColor: '#337ab7',
                cancelButtonColor: '#ccc',
                confirmButtonText: `Yes, Proceed`,
            });
            if(result.isConfirmed) {
                Swal.fire({
                    title: 'Processing...',
                    text: "Please wait a second for a response...",
                    icon: 'success',
                    showConfirmButton: false,
                    confirmButtonColor: '#027937',
                    cancelButtonColor: '#d33',
                });
                const fa_two = Number(!storedTwoFA);
                const datas = {
                    twoFA: fa_two
                }
                const response = await fetch(API_ROUTES.TWO_FA_TOGGLE, {
                    method: "POST",
                    body: objectToFormData(datas),
                    headers: {'token': userData?.responseDetails?.token},
                });
                const responseJSON = await response.json();
                if(responseJSON.status.toString() === "200"){
                    Swal.fire({
                        title: "Successful",
                        html: `2FA has been ${captions1}`,
                        icon: "success",
                        timer: 4000
                    });
                    setStoredTwoFA(fa_two);
                    const userDataJson = localStorage.getItem("userDataFs");
                    if (userDataJson) { // update an item in object
                        const userData = JSON.parse(userDataJson);
                        userData.responseDetails.twoFa_status = fa_two;
                        localStorage.setItem("userDataFs", JSON.stringify(userData));
                    }
                }else{
                    if(responseJSON.status.toString() === "500"){
                        Swal.fire({
                            title: "Error",
                            text: responseJSON.required_fields,
                            icon: "error",
                        });
                        return;
                    }
                    Swal.fire({
                        title: "Error",
                        text: responseJSON.message,
                        icon: "error",
                    });
                }
            }
        } catch (error) {
            if(error.response){
                errorFunction(error);
            }
        }
    }

    const handleShowDiv = (text) => {
        setShowText(text);
    }

    const changePassword = async (data) => {
        try {
            const response = await fetch(API_ROUTES.CHANGE_PASSWORD, {
                method: "POST",
                body: objectToFormData(data),
                headers: {'token': userData?.responseDetails?.token},
            });
            const responseJSON = await response.json();
            isAuthExpired(responseJSON); // check for expired login

            Swal.fire({
                title: 'Processing...',
                text: "Please wait a second for a response...",
                icon: 'success',
                showConfirmButton: false,
                confirmButtonColor: '#027937',
                cancelButtonColor: '#d33',
            });

            if(responseJSON.status.toString() === "200"){
                const userData1 = {};
                userData1.isLoggedIn = true;
                userData1.responseDetails = {
                    token: userData.responseDetails.token,
                    apiKey: userData.responseDetails.apiKey,
                    email: userData.responseDetails.email,
                    name: userData.responseDetails.name,
                }
                userData1.hsh = responseJSON.data;
                localStorage.setItem('userDataFs', JSON.stringify(userData1));

                Swal.fire({
                    title: "Successful",
                    text: "Your password has been updated",
                    icon: "success",
                    timer: 4000
                });
                setTimeout(() => {
                    reset1();
                }, 500);
            }else{
                if(responseJSON.status.toString() === "500"){
                    Swal.fire({
                        title: "Error",
                        text: responseJSON.required_fields,
                        icon: "error",
                    });
                    return;
                }
                Swal.fire({
                    title: "Error",
                    text: responseJSON.message,
                    icon: "error",
                });
            }
        } catch (error) {
            if(error.response){
                errorFunction(error);
            }
        }
    }

    
    const changeAPIKey = async (data) => {
        try {
            const result = await Swal.fire({
                title: `Update your API key?`,
                text: 'This cannot be undone, proceed to update?',
                icon: 'question',
                iconHtml: '?',
                showCancelButton: true,
                confirmButtonColor: '#337ab7',
                cancelButtonColor: '#ccc',
                confirmButtonText: `Yes, Proceed`,
            });
              
            if(result.isConfirmed) {
                Swal.fire({
                    title: 'Processing...',
                    text: "Please wait a second for a response...",
                    icon: 'success',
                    showConfirmButton: false,
                    confirmButtonColor: '#027937',
                    cancelButtonColor: '#d33',
                });
                const response = await fetch(API_ROUTES.CHANGE_APIKEY, {
                    method: "POST",
                    body: objectToFormData(data),
                    headers: {'token': userData?.responseDetails?.token},
                });
                const responseJSON = await response.json();
                isAuthExpired(responseJSON); // check for expired login
    
                if(responseJSON.status.toString() === "200"){
                    const userData1 = {};
                    userData1.isLoggedIn = true;
                    userData1.responseDetails = {
                        token: userData.responseDetails.token,
                        apiKey: responseJSON.data.ApiKey,
                        email: userData.responseDetails.email,
                        name: userData.responseDetails.name,
                    }
                    userData1.hsh = userData.responseDetails.hsh;
                    localStorage.setItem('userDataFs', JSON.stringify(userData1));
                    setApiKeyText("API key was updated");
    
                    Swal.fire({
                        title: "Successful",
                        text: "Your API key has been updated",
                        icon: "success",
                        timer: 4000
                    });
                    setTimeout(() => {
                        reset1();
                    }, 500);
                }else{
                    if(responseJSON.status.toString() === "500"){
                        Swal.fire({
                            title: "Error",
                            text: responseJSON.required_fields,
                            icon: "error",
                        });
                        return;
                    }
                    Swal.fire({
                        title: "Error",
                        text: responseJSON.message,
                        icon: "error",
                    });
                }
            }
        } catch (error) {
            if(error.response){
                errorFunction(error);
            }
        }
    }


    const hideStringWithAsterisks = (inputString, start, end) => {
        if (start < 0) start = 0;
        if (end > inputString.length) end = inputString.length;
      
        const visiblePart = inputString.substring(0, start);
        const hiddenPart = '*'.repeat(end - start);
        const visibleEnd = inputString.substring(end);
        return visiblePart + hiddenPart + visibleEnd;
    }

    
    let str = userData?.responseDetails?.apiKey;
    let shortenedStr = str.substring(0, 45);

    const showAPI = () => {
        setShowAPIKey(!showAPIKey);
    }

    const textToCopyRef = useRef(null);

    const handleCopyClick = () => {
        if (textToCopyRef.current) {
            textToCopyRef.current.select();
            document.execCommand('copy');
        }
    }


    return (
        <>
            <Loaders />

            <div className="flapt-page-wrapper mainContent">
                <Menus />

                <div className="flapt-page-content">
                    <HeaderTop />

                    <div className="main-content introduction-farm mt--20">
                        <div className="content-wraper-area">
                            <div className="dashboard-area">
                                <div className="container-fluid mb-90">
                                    <div className="row g-4 pl-15 pl-xs-0">
                                        <div className="col-lg-10 col-sm-11 p-xs-0"> 
                                            <div className="card">
                                                <div className="card-header">
                                                    <h3>{showText}</h3>
                                                </div>
                                                <div className="card-body formInputs">
                                                    <div className="horizontal-wizard-wrapper vertical-options">
                                                    <div className="row">


                                                        <div className="col-md-8 pl-xs-5 pr-xs-5">
                                                            <div className="tab-content pt-20 pl-10 pr-5 pl-xs-0 pr-xs-0 pb-20" id="vertical-wizard-tabContent_">
                                                                <div className="tab-pane active" id="change-passwords" role="tabpanel" aria-labelledby="change-passwords-tab">
                                                                    <form className="row" onSubmit={handleSubmit1(changePassword)}>
                                                                        <div className="card-body p-0 pb-20">
                                                                            <div className="card-wrapper_ pl-xs-0 pr-xs-0 rounded-3">

                                                                                <input value={userData?.responseDetails?.token} name="token" type="hidden" {...register1("token")} />
                                                                                <input value={userData?.responseDetails?.email} name="email" type="hidden" {...register1("email")} />
                                                                                
                                                                                <div className="col-md-12 mb-15">
                                                                                    <label className="form-label" htmlFor="pass1">Old Password</label>
                                                                                    {errors1.old_password && <label className="errors">{errors1.old_password?.message}</label>}

                                                                                    <input className="form-control" id="pass1" name="old_password" type="password" placeholder="Enter Your Old Password"
                                                                                    {...register1("old_password", { 
                                                                                        required: "Enter your old password"
                                                                                        })}
                                                                                    />
                                                                                </div>

                                                                                <div className="col-md-12 mb-15">
                                                                                    <label className="form-label" htmlFor="pass2">New Password</label>
                                                                                    {errors1.new_password && <label className="errors">{errors1.new_password?.message}</label>}

                                                                                    <input className="form-control" id="pass2" type="password" name="new_password" placeholder="Enter Your New Password" 
                                                                                    {...register1("new_password", {
                                                                                    ...passwordValidation
                                                                                    })} />
                                                                                </div>

                                                                                <div className="col-md-12 mb-30">
                                                                                    <label className="form-label" htmlFor="pass3">Confirm Password</label>
                                                                                    {errors1.confirm_password && <label className="errors">{errors1.confirm_password?.message}</label>}

                                                                                    <input className="form-control" id="pass3" type="password" placeholder="Confirm Your New Password" 
                                                                                    {...register1("confirm_password", {
                                                                                        ...confirmPasswordValidation,
                                                                                        validate: (value) => {
                                                                                            const new_pass = getValues1("new_password");
                                                                                            if(value === new_pass){
                                                                                                return true;
                                                                                            }else{
                                                                                                return "Passwords do not match"
                                                                                            }
                                                                                        },
                                                                                    })}
                                                                                    />
                                                                                </div>
                                                                                
                                                                                <div className="offset-md-7 col-md-5 text-end pr-10">
                                                                                    <button className="btn btn-primary btn-primaryi" type="submit" disabled={isSubmitting1 || !isValid1} style={{ opacity: isSubmitting1 ? '0.5' : 1 }}>Change Password </button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </form>
                                                                </div>

                                                                <div className="tab-pane" id="gen-api-key" role="tabpanel" aria-labelledby="gen-api-key-tab">
                                                                    <form className="row g-3" noValidate="" onSubmit={handleSubmit2(changeAPIKey)}>
                                                                        <input value={userData?.responseDetails?.token} name="token" type="hidden" {...register2("token")} />
                                                                        <input value={userData?.responseDetails?.email} name="email" type="hidden" {...register2("email")} />
                                                                        <div className="card_">
                                                                        <div className="card-body_ pb-20">
                                                                            <div className="pl-xs-0 pr-xs-0 rounded-3">
                                                                                <div className="col-md-12 mb-15">
                                                                                    <label className="form-label" htmlFor="email">API Key</label>
                                                                                    
                                                                                    <input ref={textToCopyRef} type="text" style={{ position:'absolute', left:'9999px', right:'9999px' }} defaultValue={userData ? str : ''}/>

                                                                                    <label className="form-control" style={{background:'#eee', color: str ? '#069' : '#888', height:showAPIKey ? '62px' : '77px', wordBreak:'break-all', whiteSpace:'normal', borderRadius:'5px', fontWeight: str ? '600' : '500'}}>
                                                                                        <label style={{ paddingRight:'60px' }}>
                                                                                            {
                                                                                                userData ? (
                                                                                                    <span>{ showAPIKey ? hideStringWithAsterisks(shortenedStr, 8, 35) : str }</span>
                                                                                                ) : 'API output will show here'
                                                                                            }
                                                                                        </label>
                                                                                        {/* {isCopied && <div>Copied to clipboard!</div>} */}
                                                                                        <span className="show_api" onClick={showAPI}>{ showAPIKey ? 'show' : 'hide' }</span>
                                                                                        <span className="copy_api" onClick={handleCopyClick}><i className="fa fa-copy"></i></span>
                                                                                    </label>
                                                                                </div>
                                                                                
                                                                                <div className="offset-md-7 col-md-5 text-end pr-10">
                                                                                    <button className="btn btn-primary btn-primaryi" type="submit">Change API Key</button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        </div>
                                                                    </form>

                                                                    {/* <table className="table table-dashed mt-30 mb-30">
                                                                        <thead>
                                                                        <tr>
                                                                            <th scope="col">SN</th>
                                                                            <th scope="col">API Keys</th>
                                                                            <th scope="col">Created</th>
                                                                        </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                        <tr>
                                                                            <th scope="row">1</th>
                                                                            <td>e095aad3f9ca4ed0a76ee5387e378daf</td>
                                                                            <td>3rd Nov, 2023 10:15am</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <th scope="row">2</th>
                                                                            <td>268773db4f26493192776e34fef498e2</td>
                                                                            <td>19th Nov, 2023 3:15pm</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <th scope="row">3</th>
                                                                            <td>1bd785ec66b84c80a2a1745d3f84d715</td>
                                                                            <td>3rd Nov, 2023 10:15am</td>
                                                                        </tr>
                                                                        </tbody>
                                                                    </table> */}
                                                                </div>

                                                                <div className="tab-pane" id="whitelist" role="tabpanel" aria-labelledby="whitelist-tab">
                                                                    {/* <MyTabs handleSubmit3={handleSubmit3} register3={register3} errors3={errors3} emailValidation={emailValidation} userData={userData} handleWhitelist={handleWhitelist} /> */}
                                                                    <MyTabs Swal={Swal} API_ROUTES={API_ROUTES} objectToFormData={objectToFormData} emailValidation={emailValidation} userData={userData} errorFunction={errorFunction} capitalizeWords={capitalizeWords} headers={headers} headers2={headers2} />
                                                                    
                                                                </div>

                                                                <div className="tab-pane" id="enable-2fa" role="tabpanel" aria-labelledby="enable-2fa-tab">
                                                                    <div className="text-center mt-30 mb-70">
                                                                        <h5 style={{fontSize:'17px',fontWeight:'500'}}>Click the button below to enable or disable 2FA</h5>
                                                                        <p className="mt-20" style={{ color:'#666', lineHeight:'24px',fontWeight:'500',fontSize:'16px' }}>Secure your devices and applications with ease with Forthsoft's easy and effective 2FA solution</p>

                                                                        <div className="offset-md-3 col-md-6 text-center mt-20 pr-10 mb-30">
                                                                            <button className="btn btn-primary btn-primaryi enable_fa" onClick={enable2FA} type="button" style={{ opacity: loanBtn ? '0.5' : 1 }}>{storedTwoFA === 1 ? "Disable" : "Enable"} 2FA</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-4 pl-30 pr-10 pl-xs-0 mt-40 mb-40 mt-xs-20 mb-xs-40 main-horizontal-header">
                                                            <div className="nav nav-pills horizontal-options" id="vertical-wizard-tab" role="tablist" aria-orientation="vertical">
                                                                <a className="nav-link active" id="change-passwords-tab" onClick={() => handleShowDiv('Change Password')} data-bs-toggle="pill" href="#change-passwords" role="tab" aria-controls="change-passwords" aria-selected="true"> 
                                                                    <div className="horizontal-wizard">
                                                                        <div className="stroke-icon-wizard"><i className="fa fa-lock"></i></div>
                                                                        <div className="horizontal-wizard-content"> 
                                                                        <h6>Change Password</h6>
                                                                        </div>
                                                                    </div>
                                                                </a>
                                                                
                                                                <a className="nav-link" id="gen-api-key-tab" data-bs-toggle="pill" onClick={() => handleShowDiv('API Key')} href="#gen-api-key" role="tab" aria-controls="gen-api-key" aria-selected="false"> 
                                                                <div className="horizontal-wizard">
                                                                    <div className="stroke-icon-wizard"><i className="fa fa-key"></i></div>
                                                                    <div className="horizontal-wizard-content"> 
                                                                    <h6>Your API Key</h6>
                                                                    </div>
                                                                </div>
                                                                </a>
                                                                
                                                                <a className="nav-link" id="whitelist-tab" data-bs-toggle="pill" onClick={() => handleShowDiv('IP Whitelisting')} href="#whitelist" role="tab" aria-controls="whitelist" aria-selected="false"> 
                                                                <div className="horizontal-wizard">
                                                                    <div className="stroke-icon-wizard"><i className="fa fa-houzz"></i></div>
                                                                    <div className="horizontal-wizard-content"> 
                                                                    <h6>IP Whitelisting</h6>
                                                                    </div>
                                                                </div>
                                                                </a>
                                                                <a className="nav-link" id="enable-2fa-tab" data-bs-toggle="pill" onClick={() => handleShowDiv('Enable/Disable 2FA')} href="#enable-2fa" role="tab" aria-controls="enable-2fa" aria-selected="false"> 
                                                                <div className="horizontal-wizard">
                                                                    <div className="stroke-icon-wizard"><i className="fa fa-user-secret"></i></div>
                                                                    <div className="horizontal-wizard-content"> 
                                                                    <h6>Enable / Disable 2FA </h6>
                                                                    </div>
                                                                </div>
                                                                </a>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footers />
                    </div>
                </div>
            </div>
        </>
    );
}