import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import '../../../libs/css/dataTable.css';
import Nulls from '../Nulls';
import FilterComponent from './FilterComponent';
import { format } from 'date-fns';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';


function AdminTable(props) {
    const { capitalizeWords, isLoading, columns, dataTotal, dataList, countPerPage, setPage, handleChange1, isFade, handleNtwk } = props;


  return (
    <div className="App">

        <FilterComponent handleChange={handleChange1} handleNtwk={handleNtwk} />

        <div style={{ opacity: isFade ? '0.3' : 1 }} >
            {
                isFade ? 
                <LoadingSpinner /> 
                :
                <DataTable
                    className="cp_datatable_admin users"
                    title=""
                    columns={columns}
                    data={dataList}
                    highlightOnHover
                    pagination
                    paginationServer
                    paginationTotalRows={dataTotal.total}
                    paginationPerPage={countPerPage}
                    paginationComponentOptions={{
                    noRowsPerPage: true
                    }}
                    onChangePage={page => setPage(page)}
                />

            }
        </div>
            
        
    </div>
  );
}

export default AdminTable;
