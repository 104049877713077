import React, { useEffect, useRef, useState } from "react";
import Loaders from "../Loaders";
import HeaderTop from "../HeaderTop";
import Menus from "../Menus";
import Footers from "../Footers";
import Swal from "sweetalert2";
import '../../../libs/css/bootstrap.min3.3.7.css';
import '../../../libs/css/sweetalert2.min.css';
import { API_ROUTES } from "../../../utils/constants";
import { useForm } from "react-hook-form";

import { useLocation } from "react-router-dom";


export default function CreateServices(props){
    const {objectToFormData, errorFunction, Helmet, capitalizeWords, isAuthExpired, allCPs} = props;

    useEffect(() => {document.title = "Create Services | Forthsoft"}, []);

    useEffect(() => {
        const loadScript = (src) => {
          return new Promise((resolve, reject) => {
            const script = document.createElement('script');
            script.src = src;
            script.async = true;
            script.onload = resolve;
            script.onerror = reject;
            document.body.appendChild(script);
          });
        };
        Promise.all([
        //   loadScript('../assets/libs_/js/jquery.min.js'),
          loadScript('../assets/libs_/js/scripts2.js'),
        ])
          .then(() => {
            // Both scripts are loaded
          })
          .catch((error) => {
            console.error('Error loading scripts:', error);
          });
        return () => {
          // Remove the scripts here if necessary
        };
    }, []);



    const userData = JSON.parse(localStorage.getItem('userDataFs'));

    const { register: register1, getValues: getValues1, setValue: setValue1, reset: reset1, handleSubmit: handleSubmit1, formState: {errors: errors1, isSubmitting: isSubmitting1, isValid: isValid1} } = useForm({mode: 'onChange'});
    
    const [operatorList, setOperatorList] = useState([]);
    const [isLoading1, setIsLoading1] = useState(true);

    


    let service_details = localStorage.getItem('service_details');
    service_details = JSON.parse(service_details);

    const location = useLocation();
    const locationUrl = location.pathname.split("/")[2];
    let serID = "";
    if(locationUrl){
        serID = locationUrl.split("--")[1].slice(3, -3);
    }

    let urls = API_ROUTES.CREATE_CP_SERVICES;
    if(serID && serID.trim() !== ""){
        urls = API_ROUTES.EDIT_CP_SERVICES;
    }

    useEffect(() => {
        if(location.pathname === "/create-service"){
          localStorage.removeItem('service_details');
          reset1();
        }
    }, [location.pathname]);
    


    const getOperators = async () => {
        setIsLoading1(true);
        try {
            const response = await fetch(API_ROUTES.NETWORKS, {
                method: "POST",
                headers: {'token': userData?.responseDetails?.token},
            });
            const responseJSON = await response.json();
            if (responseJSON.status.toString() === "200") {
                const responseData = responseJSON.data;
                setOperatorList(responseData);
                setIsLoading1(false);
            }
        } catch (error) {
            console.log('Error getting data: ', error);
            setOperatorList([]);
            setIsLoading1(false);
        }
    }


    

    useEffect(() => {
        if(service_details){
            setValue1("cp_id", service_details.user_id || '');
            setValue1("network_id", service_details.network_id || '');
        }
    }, [service_details]);


    useEffect(() => {
        getOperators();
    }, []);


    const [opts, setOpts] = useState('');
    const [cpss, setCpss] = useState('');

   

    const createServices = async (data) => {
        try {
            const response = await fetch(urls, {
                method: "POST",
                body: objectToFormData(data),
                headers: {'token': userData?.responseDetails?.token},
            });

            const responseJSON = await response.json();
            isAuthExpired(responseJSON); // check for expired login

            Swal.fire({
                title: 'Processing...',
                text: "Please wait a second for a response...",
                icon: 'success',
                showConfirmButton: false,
                confirmButtonColor: '#027937',
                cancelButtonColor: '#d33',
            });

            if(responseJSON.status.toString() === "200"){
                Swal.fire({
                    title: "Successful",
                    // text: "A service has been created!",
                    text: `A service has been ${!serID ? "created" : "updated"}!`,
                    icon: "success",
                    timer: 4000
                });

                service_details.user_id = data.cp_id;
                service_details.serviceName = data.service_name;
                service_details.amount = data.amount;
                service_details.serviceId = data.service_id;
                service_details.productCode = data.product_code;
                service_details.productId = data.product_id;
                service_details.keyword = data.keyword;
                service_details.product_name = data.product_name;
                service_details.type = data.type;
                service_details.network_id = data.network_id;
                service_details.shortcode = data.shortcode;
                service_details.service_provider = data.service_provider;
                localStorage.setItem('service_details', JSON.stringify(service_details));
                
                if(!serID){
                    setTimeout(() => {
                        reset1();
                    }, 500);
                }
            }else{
                if(responseJSON.status.toString() === "500"){
                    Swal.fire({
                        title: "Error",
                        text: responseJSON.required_fields,
                        icon: "error",
                    });
                    return;
                }
                Swal.fire({
                    title: "Error",
                    text: responseJSON.message,
                    icon: "error",
                });
            }
        } catch (error) {
            if(error.response){
                errorFunction(error);
            }
        }
    }




    return (
        <>
            <Loaders />

            <div className="flapt-page-wrapper mainContent">
                <Menus />

                <div className="flapt-page-content">
                    <HeaderTop />

                    <div className="main-content introduction-farm mt-5">
                        <div className="content-wraper-area">
                            <div className="dashboard-area">
                                <div className="container-fluid mb-90 mt-20">
                                    <div className="row g-4 pl-15 pl-xs-0">
                                        <div className="col-lg-10 col-sm-11 p-xs-0"> 
                                            <div className="card">
                                                <div className="card-header">
                                                    <h3>Create Services</h3>
                                                </div>
                                                <div className="card-body formInputs">
                                                    <div className="horizontal-wizard-wrapper vertical-options">
                                                        <div className="row">
                                                            <div className="col-md-12 pl-xs-5 pr-xs-5">
                                                                <div className="tab-content pt-20 pl-10 pr-5 pl-xs-0 pr-xs-0 pb-20" id="vertical-wizard-tabContent_">
                                                                    <div className="tab-pane active" id="change-passwords" role="tabpanel" aria-labelledby="change-passwords-tab">
                                                                        <form className="row" onSubmit={handleSubmit1(createServices)}>
                                                                            <div className="card-body p-10 pb-20">
                                                                                <div className="row">
                                                                                    <div className="col-sm-6 mb-5">
                                                                                        <label className="form-label" htmlFor="cp_id">Select CP</label>
                                                                                        {errors1.cp_id && <label className="errors">{errors1.cp_id?.message}</label>}

                                                                                        <select className='filter_text' {...register1("cp_id")} style={{ textTransform:'capitalize' }}>
                                                                                            <option value="">-Select CP-</option>
                                                                                            {
                                                                                                allCPs.length !== 0 && allCPs.map(mycp => { 
                                                                                                    return <option key={mycp.id} value={mycp.id}>{mycp.name}</option>
                                                                                                })
                                                                                            }
                                                                                        </select>
                                                                                    </div>

                                                                                    <input className="form-control" id="id" name="id" type="hidden"
                                                                                        defaultValue={service_details ? service_details.id : ''}
                                                                                        {...register1("id")}
                                                                                    />

                                                                                    <div className="col-sm-6 mb-15">
                                                                                        <label className="form-label" htmlFor="service_name">Service Name</label>
                                                                                        {errors1.service_name && <label className="errors">{errors1.service_name?.message}</label>}

                                                                                        <input className="form-control" id="service_name" name="service_name" type="text" placeholder="Enter Service Name"
                                                                                        style={{ textTransform:'capitalize' }}
                                                                                        defaultValue={service_details ? service_details.serviceName : ''} 
                                                                                        {...register1("service_name", { 
                                                                                            required: "Enter service name"
                                                                                            })}
                                                                                        />
                                                                                    </div>

                                                                                    <div className="col-sm-6 mb-15">
                                                                                        <label className="form-label" htmlFor="product_name">Product Name</label>
                                                                                        {errors1.product_name && <label className="errors">{errors1.product_name?.message}</label>}

                                                                                        <input className="form-control" id="product_name" name="product_name" type="text" placeholder="Enter Product Name"
                                                                                        defaultValue={service_details ? service_details.product_name : ''} 
                                                                                        style={{ textTransform:'capitalize' }}
                                                                                        {...register1("product_name", { 
                                                                                            required: "Enter product name"
                                                                                            })}
                                                                                        />
                                                                                    </div>

                                                                                    <div className="col-md-6 mb-15">
                                                                                        <label className="form-label" htmlFor="amount">Amount</label>
                                                                                        {errors1.amount && <label className="errors">{errors1.amount?.message}</label>}

                                                                                        <input className="form-control" id="amount" type="number" name="amount" 
                                                                                        onKeyPress={(e) => {
                                                                                            if (!/[0-9]/.test(e.key)) {
                                                                                              e.preventDefault();
                                                                                            }
                                                                                        }}
                                                                                        defaultValue={service_details ? service_details.amount : ''} 
                                                                                        placeholder="Enter Amount" 
                                                                                        {...register1("amount", {
                                                                                            required: 'Amount field is required',
                                                                                            pattern: {
                                                                                                value: /^[0-9]+$/,
                                                                                                message: 'Amount is invalid',
                                                                                            }
                                                                                        })} />
                                                                                    </div>

                                                                                    <div className="col-sm-6 mb-15">
                                                                                        <label className="form-label" htmlFor="service_id">Service ID</label>
                                                                                        {errors1.service_id && <label className="errors">{errors1.service_id?.message}</label>}

                                                                                        <input className="form-control" id="service_id" type="number"
                                                                                        defaultValue={service_details ? service_details.serviceId : ''} 
                                                                                        onKeyPress={(e) => {
                                                                                            if (!/[0-9]/.test(e.key)) {
                                                                                              e.preventDefault();
                                                                                            }
                                                                                        }}
                                                                                        placeholder="Enter Service ID" 
                                                                                        {...register1("service_id", {
                                                                                            pattern: {
                                                                                                value: /^[0-9]+$/,
                                                                                                message: 'Service ID is invalid',
                                                                                            }
                                                                                        })}
                                                                                        />
                                                                                    </div>

                                                                                    <div className="col-sm-6 mb-15">
                                                                                        <label className="form-label" htmlFor="product_id">Product ID</label>
                                                                                        {errors1.product_id && <label className="errors">{errors1.product_id?.message}</label>}

                                                                                        <input className="form-control" id="product_id" type="number" 
                                                                                        defaultValue={service_details ? service_details.productId : ''} 
                                                                                        onKeyPress={(e) => {
                                                                                            if (!/[0-9]/.test(e.key)) {
                                                                                              e.preventDefault();
                                                                                            }
                                                                                        }}
                                                                                        placeholder="Enter Product ID" 
                                                                                        {...register1("product_id", {
                                                                                            pattern: {
                                                                                                value: /^[0-9]+$/,
                                                                                                message: 'Product ID is invalid',
                                                                                            }
                                                                                        })}
                                                                                        />
                                                                                    </div>

                                                                                    <div className="col-sm-6 mb-15">
                                                                                        <label className="form-label" htmlFor="product_code">Product Code (Optional)</label>
                                                                                        {errors1.product_code && <label className="errors">{errors1.product_code?.message}</label>}

                                                                                        <input className="form-control" id="product_code" type="number" placeholder="Enter Product Code" 
                                                                                        defaultValue={service_details ? service_details.productCode : ''} 
                                                                                        {...register1("product_code")}
                                                                                        />
                                                                                    </div>

                                                                                    <div className="col-sm-6 mb-15">
                                                                                        <label className="form-label" htmlFor="keyword">Keyword</label>
                                                                                        {errors1.keyword && <label className="errors">{errors1.keyword?.message}</label>}

                                                                                        <input className="form-control" style={{ textTransform:'uppercase' }} id="keyword" type="text" placeholder="Enter Keyword" 
                                                                                        defaultValue={service_details ? service_details.keyword : ''} 
                                                                                        {...register1("keyword")}
                                                                                        />
                                                                                    </div>

                                                                                    <div className="col-sm-6 mb-5">
                                                                                        <label className="form-label" htmlFor="keyword">Select Network</label>
                                                                                        {errors1.keyword && <label className="errors">{errors1.keyword?.message}</label>}

                                                                                        <select className='filter_text' {...register1("network_id")}>
                                                                                            <option value="">-Select Network-</option>
                                                                                            {
                                                                                                operatorList.length !== 0 && operatorList.map(operator => { 
                                                                                                    return <option key={operator.id} value={operator.id}>{operator.network}</option>
                                                                                                })
                                                                                            }
                                                                                        </select>
                                                                                    </div>

                                                                                    <div className="col-sm-6 mb-15">
                                                                                        <label className="form-label" htmlFor="service_provider">Service Provider</label>
                                                                                        {errors1.service_provider && <label className="errors">{errors1.service_provider?.message}</label>}

                                                                                        <input className="form-control" id="service_provider" type="text" placeholder="Enter Service Provider" 
                                                                                        defaultValue={service_details ? service_details.service_provider : ''} 
                                                                                        {...register1("service_provider")}
                                                                                        />
                                                                                    </div>

                                                                                    <div className="col-sm-6 mb-15">
                                                                                        <label className="form-label" htmlFor="shortcode">Shortcode</label>
                                                                                        {errors1.shortcode && <label className="errors">{errors1.shortcode?.message}</label>}

                                                                                        <input className="form-control" id="shortcode" type="number" 
                                                                                        defaultValue={service_details ? service_details.shortcode : ''} 
                                                                                        onKeyPress={(e) => {
                                                                                            if (!/[0-9]/.test(e.key)) {
                                                                                              e.preventDefault();
                                                                                            }
                                                                                        }}
                                                                                        placeholder="Enter Shortcode" 
                                                                                        {...register1("shortcode", {
                                                                                            pattern: {
                                                                                                value: /^[0-9]+$/,
                                                                                                message: 'Shortcode is invalid',
                                                                                            }
                                                                                        })}
                                                                                        />
                                                                                    </div>

                                                                                    <div className="col-sm-6 mb-15">
                                                                                        <label className="form-label" htmlFor="type">Type (Optional)</label>
                                                                                        {errors1.type && <label className="errors">{errors1.type?.message}</label>}

                                                                                        <input className="form-control" id="type" type="number" placeholder="Enter Type" 
                                                                                        defaultValue={service_details ? service_details.type : ''} 
                                                                                        {...register1("type")}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                    
                                                                                <div className="row mt-30">
                                                                                    <div className="offset-md-6 col-md-6 text-end pr-10">
                                                                                        <button className="btn btn-primary btn-primaryi" type="submit" disabled={isSubmitting1 || !isValid1} style={{ opacity: isSubmitting1 ? '0.5' : 1 }}>{serID && serID.trim() === "" ? "Create" : "Edit"} Service </button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </form>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footers />
                    </div>
                </div>
            </div>
        </>
    );
}