import React, { useEffect, useState } from "react";
import Loaders from "./Loaders";
import HeaderTop from "./HeaderTop";
import Menus from "./Menus";
import Footers from "./Footers";
import { Link } from "react-router-dom";
import { API_ROUTES } from "../../utils/constants";
import { format } from "date-fns";
import LoadingSpinner from "./LoadingSpinner/LoadingSpinner";
import Clock from 'react-clock';


const Index = (props) => {
    const { capitalizeWords, objectToFormData, isAuthExpired, today, resetTimeToMidnight, resetTimeToB4Midnight, formatDate, allCPs, isLoading1 } = props;
    const [isFade, setIsFade] = useState(true);
    const [todayRevLoading, setTodayRevLoading] = useState(true);
    
    const [serviceCounts, setServiceCounts] = useState(0);
    const [statCounts, setStatCounts] = useState({});
    const [totalSub, setTotalSub] = useState(0);
    const [unSubscribersCounts, setUnSubscriptionCounts] = useState(0);
    
    const [revenueLastMonth, setRevenueLastMonth] = useState(0);
    const [revenueThisMonth, setRevenueThisMonth] = useState(0);
    const [allTotals, setAllTotal] = useState(0);
    const [percentThisMonth, setPercentThisMonth] = useState(0);
    const [percentLastMonth, setPercentLastMonth] = useState(0);
    
    const [todayRev, setTodayRev] = useState('');
    const [syncs, setSyncs] = useState({Telco_Sync_Count: 0, Partner_Sync_Count: 0});


    useEffect(() => {document.title = "Dashboard | Forthsoft"}, []);
    const userData = JSON.parse(localStorage.getItem('userDataFs'));

    const currentDate = new Date();
    const thisMonth = currentDate.toLocaleString('default', { month: 'long' });

    useEffect(() => {
        const loadScript = (src) => {
          return new Promise((resolve, reject) => {
            const script = document.createElement('script');
            script.src = src;
            script.async = true;
            script.onload = resolve;
            script.onerror = reject;
            document.body.appendChild(script);
          });
        };
        Promise.all([
        //   loadScript('../assets/libs_/js/jquery.min.js'),
          loadScript('../assets/libs_/js/scripts2.js'),
        ])
          .then(() => {
            // Both scripts are loaded
          })
          .catch((error) => {
            console.error('Error loading scripts:', error);
          });
        return () => {
          // Remove the scripts here if necessary
        };
    }, []);




    const getStats = async () => {
        const currentDate = new Date();
        const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
        lastDayOfMonth.setHours(23, 59, 59, 999);
        const formattedBeginningOfMonth = formatDate(firstDayOfMonth);
        const formattedEndOfMonth = formatDate(lastDayOfMonth);
        
        const dataString = { 
            from: formattedBeginningOfMonth,
            to: formattedEndOfMonth,
        };
        // console.log(dataString);
        setTodayRevLoading(true);
        try {
            const response = await fetch(API_ROUTES.STATISTICS, {
                method: "POST",
                body: objectToFormData(dataString),
                headers: {'token': userData?.responseDetails?.token},
            });
            const responseJSON = await response.json();
            if (responseJSON.status.toString() === "200") {
                setStatCounts(responseJSON.data);
                setTodayRevLoading(false);
            }
        } catch (error) {
            console.log('Error getting data: ', error);
            setRevenueThisMonth(0);
            setTodayRevLoading(false);
        }
    }

    useEffect(() => {
        getStats();
    }, []);



    const get_today_rev = async (myFilters) => {
        const extractID = myFilters.cp_id;
        let dataString = {};
        if(myFilters){
            dataString = { 
                // cp_id: extractID,
                // cp_id: 1,
                fro: myFilters.fro,
                to: myFilters.to,
            } 
        }else{
            dataString = { 
                // cp_id: 1,
                fro: resetTimeToMidnight(formatDate(today)),
                to: resetTimeToB4Midnight(formatDate(today)),
            };
        }
        console.log(dataString);
        setTodayRevLoading(true);
        try {
            const response = await fetch(API_ROUTES.TOTAL_REVENUE, {
                method: "POST",
                body: objectToFormData(dataString),
                headers: {'token': userData?.responseDetails?.token},
            });
            const responseJSON = await response.json();
            console.log("responseJSON ===");
            console.log(responseJSON);
            isAuthExpired(responseJSON);

            if (responseJSON.status.toString() === "200") {
                setTodayRevLoading(false);

                const responseData = responseJSON.data;
                const isArrs = Array.isArray(responseJSON.data);
                let totalRevs = 0;

                if(isArrs){
                    totalRevs = responseData.reduce((sum, item) => sum + item.totalRevenue, 0);
                }else{
                    totalRevs = responseData.totalRevenue;
                }
                console.log("totalRevs ===");
                console.log(totalRevs);
                setTodayRev(totalRevs);
                
            }
        } catch (error) {
            console.log('Error getting data: ', error);
            setTodayRevLoading(false);
        }
    }

    useEffect(() => {
        get_today_rev({ fro: resetTimeToMidnight(formatDate(today)), to: resetTimeToB4Midnight(formatDate(today)) });
    }, [today]);


    const [dataList, setDataList] = useState([]);

    const getData = async (network="", keyword="", params="") => {
        const dataString = { 
            page: 1,
            per_page: 6,
            // network_id: network,
            // [filter_text1]: keyword,
        };
        setTodayRevLoading(true);

        try {
            const response = await fetch(API_ROUTES.VIEW_CP_SERVICES, {
                method: "POST",
                body: objectToFormData(dataString),
                headers: {'token': userData?.responseDetails?.token},
            });

            const responseJSON = await response.json();
            isAuthExpired(responseJSON); // check for expired login

            if (responseJSON.status.toString() === "200") {
                const responseData = responseJSON.data;
                // console.log("responseData");
                // console.log(responseData);
                setDataList(responseData);
                setTodayRevLoading(false);
            }
        } catch (error) {
            console.log('Error getting data: ', error);
            setDataList([]);
            setTodayRevLoading(false);
        }
    }


    const getSubData = async (isLoad=true, myFilters) => {
        const extractID = myFilters.cp_id.split("|")[0];
        //sssss

        let dataString = {};
        if(myFilters){
            dataString = { 
                cp_id: myFilters.cp_id ? extractID : '',
                fro: myFilters.fro,
                to: myFilters.to,
            } 
        }else{
            dataString = { 
                cp_id: myFilters.cp_id ? extractID : '',
                fro: resetTimeToMidnight(formatDate(today)),
                to: resetTimeToB4Midnight(formatDate(today)),
            };
        }
        setTodayRevLoading(true);
        try {
            const response = await fetch(API_ROUTES.SUBSCRIPTION, {
                method: "POST",
                body: objectToFormData(dataString),
                headers: {'token': userData?.responseDetails?.token},
            });
            const responseJSON = await response.json();
            isAuthExpired(responseJSON); // check for expired login

            if (responseJSON.status.toString() === "200") {
                
                const responseData = responseJSON.data;
                const totalCount = responseData.reduce((sum, item) => sum + item.count, 0);

                setTotalSub(totalCount);
                setTodayRevLoading(false);
            }else{
                setTotalSub(0);
                setTodayRevLoading(false);
            }
        } catch (error) {
            console.log('Error getting data: ', error);
            setTotalSub(0);
            setTodayRevLoading(false);
        }
    }

    
    useEffect(() => {
        getData("", "", "");
        getSubData(true, { cp_id: "", fro: resetTimeToMidnight(formatDate(today)), to: resetTimeToB4Midnight(formatDate(today)) });
    }, []);



    useEffect(() => {
        const allTotal = parseFloat(revenueLastMonth) + parseFloat(revenueThisMonth);
        setAllTotal(allTotal);
        
        const lastMonthVal = parseFloat((parseFloat(revenueLastMonth) / parseFloat(allTotal))) * 100;
        const thisMonthVal = parseFloat(parseFloat(revenueThisMonth) / parseFloat(allTotal)) * 100;

        setPercentThisMonth(isNaN(thisMonthVal) ? 0 : thisMonthVal.toFixed(1));
        setPercentLastMonth(isNaN(lastMonthVal) ? 0 : lastMonthVal.toFixed(1));
    }, [revenueLastMonth, revenueThisMonth]);

    currentDate.setMonth(currentDate.getMonth() - 1);



    const [value, setValue] = useState(new Date());

    useEffect(() => {
        const interval = setInterval(() => setValue(new Date()), 1000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    const formattedDate = value.toLocaleDateString('en-GB', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    });
    const formattedTime = value.toLocaleTimeString('en-GB', {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: true 
    });



    return (
        <>
            <Loaders />

            <div className="flapt-page-wrapper mainContent">
                <Menus />

                <div className="flapt-page-content" style={{ backgroundColor:'#ccc' }}>
                    <HeaderTop />

                    <div className="main-content introduction-farm pl-15 pr-15 pl-xs-0 pr-xs-0">
                        <div className="content-wraper-area">
                            <div className="dashboard-area">
                                <div className="container-fluid">
                                    <div className="row g-4">
                                        <div className="col-12 mt--5 mb-30 text-center">
                                            <div className="">
                                                <div className="dashboard-header-title">
                                                    <h2 className="mb-0 mt--5">Admin Dashboard</h2>
                                                </div>
                                            </div>
                                        </div>


                                        {/* {
                                        isLoading1 ?
                                            <div className="mt-40">
                                                <LoadingSpinner /> 
                                            </div>
                                        : */}
                                            <>
                                                <div className="offset-xxl-1 col-xxl-3 offset-xl-1 col-xl-4 offset-lg-1 col-lg-5 offset-md-0 col-md-5 offset-sm-0 col-sm-6 mt-md-30 mt-xs--10">
                                                    <div className="card card_height mt-sm-15 mt-xs--15">
                                                        <div className="card-body" data-intro="">
                                                            <div className="single-widget">
                                                                {
                                                                    todayRevLoading ? 
                                                                    <div className="mt-40">
                                                                        <LoadingSpinner /> 
                                                                    </div>
                                                                    :
                                                                    <div>
                                                                        <div className="row wIcons">
                                                                            <div className="col-md-4"><i className="bx bx-history"></i></div>
                                                                            <div className="col-md-8 text-start" style={{marginTop:'-10px'}}>
                                                                                <h3 className="mt-25">Statistics</h3>
                                                                            </div>
                                                                        </div>

                                                                        <div className="mt-25 mb-xs-15">
                                                                            <div className="row mb-10">
                                                                                <div className="col-5">Revenue</div>
                                                                                <div className="col-7 text-end colored">
                                                                                    { todayRev !== "" ? todayRev.toLocaleString() : '....' } <span style={{ fontSize:'12px'}}>(Today)</span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row mb-10">
                                                                                <div className="col-5">No. of Services</div>
                                                                                <div className="col-7 text-end colored">{Object.keys(statCounts).length !== 0 ? statCounts.Services_Count.toLocaleString() : 0} Services</div>
                                                                            </div>
                                                                            <div className="row mb-10">
                                                                                <div className="col-5">No. of CPs</div>
                                                                                <div className="col-7 text-end colored">{allCPs.length} CPs</div>
                                                                            </div>
                                                                            <div className="row mb-10">
                                                                                <div className="col-5">No. of Subscription</div>
                                                                                <div className="col-7 text-end colored">{totalSub.toLocaleString()} Subscriptions</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            
                                                <div className="col-sm-5 col-lg-5 col-xl-4 col-xxl-3 offset-md-0 col-md-5 mt-md-30 mt-xs--10">
                                                    <div className="card card_height mt-sm-15 mt-xs--15">
                                                        <div className="card-body" data-intro="New Orders">
                                                            <div className="Sample__container">
                                                                <main className="Sample__container__content">
                                                                    <div className="date-display">{formattedDate}</div>
                                                                    <div className="time-display">{formattedTime}</div>
                                                                    <Clock size={200} value={value} />
                                                                </main>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        {/* } */}
                                    </div>


                                    {
                                        !isLoading1 &&
                                        <div className="row mt-0 mt-sm-5 mt-xs-0">
                                            <div className="col-lg-12 col-md-12 mb-20 mb-sm-10">
                                                <div className="card">
                                                    <div className="card-body pt-5">
                                                        <div className="card-title border-bootom-none mb-10 d-flex align-items-center justify-content-between">
                                                            <h6 className="mb-0">Last 6 Services</h6>
                                                            <div className="dashboard-dropdown table_dropdown">
                                                                <div className="dropdown">
                                                                    <button className="btn btn_dots dropdown-toggle_" type="button" id="dashboardDropdown57" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="ti-more-alt"></i></button>
                                                                    
                                                                    <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dashboardDropdown57">
                                                                        <Link className="dropdown-item" to="/service-list"><i className="ti-eye"></i>View All</Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="table-responsive text-nowrap">
                                                            <table className="table table-centered table-nowrap table-hover mb-0">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Service ID</th>
                                                                        <th>Service Name</th>
                                                                        <th>Network</th>
                                                                        <th>Amount</th>
                                                                        <th>Date</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        dataList.length !== 0 && dataList.map(item => {
                                                                            let isNetwork = <div style={{ color:'orange' }}>MTN</div>
                                                                            if(item.network_id === 2){
                                                                                isNetwork = <div style={{ color:'#72ae49' }}>GLO</div>
                                                                            }else if(item.network_id === 3){
                                                                                isNetwork = <div style={{ color:'red' }}>AIRTEL</div>
                                                                            }else if(item.network_id === 4){
                                                                                isNetwork = <div style={{ color:'#82AA40' }}>9MOBILE</div>
                                                                            }
                                                                            return (
                                                                                <tr key={item.id}>
                                                                                    <td>{item.serviceId}</td>
                                                                                    <td>{item.serviceName}</td>
                                                                                    <td>{isNetwork}</td>
                                                                                    <td>{item.amount ? '₦'+parseFloat(item.amount).toLocaleString() : '0.00'}</td>
                                                                                    <td>{item.created_at ? format(new Date(item.created_at), 'MMM d, yyyy h:mma') : 'Not Specified'}</td>
                                                                                </tr>
                                                                            );
                                                                        })
                                                                    }

                                                                    {
                                                                        dataList.length === 0 && 
                                                                        <tr style={{ textAlign:'center' }}>
                                                                            <td style={{ fontSize:'17px' }} colSpan="5" className="pt-20 pb-20">No subscribers found for the last 7 days</td>
                                                                        </tr>
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* <div className="col-lg-4 mb-20">
                                                <div className="card mt-xs--15">
                                                    <div className="card-body pt-5">
                                                        <div className="card-title mb-30 d-flex align-items-center justify-content-between">
                                                            <h6 className="mb-0">Analysis</h6>
                                                            <div className="dashboard-dropdown">
                                                                <div className="dropdown">
                                                                    <button className="btn btn_dots dropdown-toggle_" type="button" id="dashboardDropdown60" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="ti-more-alt"></i></button>

                                                                    <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dashboardDropdown57">
                                                                        <Link className="dropdown-item" to="/"><i className="ti-eye"></i>View All</Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="chart-area">
                                                            <div id="pie-chart"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>


                        <Footers />
                    </div>

                </div>
            </div>
        </>
    );
}

export default Index;