import { BrowserRouter } from 'react-router-dom';
import './App.css';
import RouterComponent from './Components/Routes/RouterComponent';

function App() {

  function getRandomThreeDigitNumber() {
    return Math.floor(Math.random() * 900) + 100; // 2 digits
  }

  return (
    <BrowserRouter>
      <RouterComponent getRandomThreeDigitNumber={getRandomThreeDigitNumber} /> {/* useNavigate is used in this component */}
    </BrowserRouter>
  );
}

export default App;
