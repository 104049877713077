import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

export default function Menus(){
    const navigate = useNavigate();

    const logout = () => {
        localStorage.removeItem('userDataFs');
        navigate('/login');
    }

    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const location = useLocation();

    const [isLogsMenuOpen, setIsLogsMenuOpen] = useState(false);
    const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
    const [isSub1MenuOpen, setIsSub1MenuOpen] = useState(false);
    const [isSub2MenuOpen, setIsSub2MenuOpen] = useState(false);

    const toggleLogsMenu = () => {
        setIsLogsMenuOpen(!isLogsMenuOpen);
    };
    const toggleSubMenu = () => {
        setIsSubMenuOpen(!isSubMenuOpen);
    };

    const toggleSub1SubMenu = () => {
        setIsSub1MenuOpen(!isSub1MenuOpen);
    };

    const toggleAdminSubMenu = () => {
        setIsSub2MenuOpen(!isSub2MenuOpen);
    };

    const locationUrl = location.pathname.split("/")[1];



    const excludedPaths = ['/dashboard', '/settings', '/create-service', '/create-cp', '/edit-cp', '/edit-service', '/create-admins'];

    return (
        <div className="flapt-sidemenu-wrapper">
            <div className="flapt-logo">
                <Link to="/dashboard"><img className="desktop-logo" src="../assets/images/logo2.png" alt="Forthsoft Logo" /> <img className="small-logo" src="../assets/images/small-logo.png" alt="Forthsoft Logo" /></Link>
            </div>

            <div className="flapt-sidenav" id="flaptSideNav">
                <div className={`side-menu-area ${(!excludedPaths.includes(location.pathname)) ? (locationUrl === "edit-cp" || locationUrl === "edit-service") ? 'mt-20' : 'mt-90' : 'mt-20'}`}>
                    <nav>
                        <ul className="sidebar-menu" data-widget="tree">
                            {/* <li className="menu-header-title">Dashboard</li> */}
                            
                            <li className={location.pathname === '/dashboard' ? 'active' : ''}>
                                <Link to="/dashboard">
                                    <i className="bx bx-home"></i>
                                    <span>Dashboard</span>
                                </Link>
                            </li>

                            <li className={`treeview ${location.pathname === '/service-list' || location.pathname === '/create-service' ? 'active' : ''}`}>
                                <a href="#" onClick={toggleLogsMenu}>
                                    <i className="ti ti-plug"></i>
                                    <span>Services List</span>
                                    <i className={`fa fa-angle-right ${isLogsMenuOpen ? 'rotate-icon' : ''}`}></i>
                                </a>
                                <ul className="treeview-menu" style={{ display: isLogsMenuOpen ? 'block' : 'none' }}>
                                    <li><Link to="/service-list">View Services</Link></li>
                                    <li><Link to="/create-service">Create Services</Link></li>
                                </ul>
                            </li>

                            <li className={`treeview ${(location.pathname === '/view-cp' || location.pathname === '/create-cp' || location.pathname === '/edit-cp') ? 'active' : ''}`}>
                                <a href="#" onClick={toggleSubMenu}>
                                    <i className="ti ti-rocket"></i>
                                    <span>Content Providers</span>
                                    <i className={`fa fa-angle-right ${isSubMenuOpen ? 'rotate-icon' : ''}`}></i>
                                </a>
                                <ul className="treeview-menu" style={{ display: isSubMenuOpen ? 'block' : 'none' }}>
                                    <li><Link to="/view-cp">View CP</Link></li>
                                    <li><Link to="/create-cp">Create CP</Link></li>
                                </ul>
                            </li>

                            <li className={`treeview ${(location.pathname === '/view-admins' || location.pathname === '/create-admins' || location.pathname === '/edit-cp') ? 'active' : ''}`}>
                                <a href="#" onClick={toggleAdminSubMenu}>
                                    <i className="ti ti-user"></i>
                                    <span>Admins</span>
                                    <i className={`fa fa-angle-right ${isSub2MenuOpen ? 'rotate-icon' : ''}`}></i>
                                </a>
                                <ul className="treeview-menu" style={{ display: isSub2MenuOpen ? 'block' : 'none' }}>
                                    <li><Link to="/create-admins">Create Admins</Link></li>
                                    <li><Link to="/view-admins">View Admins</Link></li>
                                </ul>
                            </li>

                            <li className={`treeview ${(location.pathname === '/subscription' || location.pathname === '/unsubscription') ? 'active' : ''}`}>
                                <a href="#" onClick={toggleSub1SubMenu}>
                                    <i className="ti ti-rocket"></i>
                                    <span>Subscription</span>
                                    <i className={`fa fa-angle-right ${isSub1MenuOpen ? 'rotate-icon' : ''}`}></i>
                                </a>
                                <ul className="treeview-menu" style={{ display: isSub1MenuOpen ? 'block' : 'none' }}>
                                    <li><Link to="/subscription">Subscription</Link></li>
                                    <li><Link to="/unsubscription">Unsubscription</Link></li>
                                </ul>
                            </li>

                            <li className={location.pathname === '/revenue-report' ? 'active' : ''}>
                                <Link to="/revenue-report">
                                    <i className="ti ti-bar-chart-alt"></i>
                                    <span>Revenue Report</span>
                                </Link>
                            </li>
                            
                            <hr />

                            <li>
                                <Link onClick={logout} to="/">
                                <i className="ti ti-power-off"></i>
                                <span>Logout</span>
                                </Link>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>

    );
}