import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import '../../../libs/css/dataTable.css';
import FilterComponent from './FilterComponent';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';


function SubscriptionTable(props) {
    const { capitalizeWords, isLoading, setIsLoading, columns, dataTotal, dataList, countPerPage, setPage, isFade, handleDuration, handleOthers, registerName, clearInputs, isAuthExpired, cpSelected, allCPs, isLoading1 } = props;


    return (
        <div className="App">
            <>
                <FilterComponent handleDuration={handleDuration} handleOthers={handleOthers} registerName={registerName} clearInputs={clearInputs} allCPs={allCPs} />

                <div style={{ opacity: isFade ? '0.3' : 1 }} >
                    <div className='text-left pl-5 mt-15 mb-5 text-black'>Default CP: {cpSelected || "All"}</div>

                    <DataTable
                        className="unsub_datatable users"
                        title=""
                        columns={columns}
                        data={dataList}
                        highlightOnHover
                        pagination
                        paginationServer
                        paginationTotalRows={dataTotal}
                        paginationPerPage={countPerPage}
                        paginationComponentOptions={{
                        noRowsPerPage: true
                        }}
                        onChangePage={page => setPage(page)}
                    />

                    {dataTotal <= 0 && <div className='mt-20'>Please filter on the filter boxes above to populate data</div>} 
                </div>
            </>
        </div>
    );
}

export default SubscriptionTable;
