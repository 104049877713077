import React, { useState, useEffect } from 'react';



function Nulls() {

  return (
    <span style={{ color:'#666', fontWeight:'400', fontSize:'15px' }}>Not Specified</span>
  );
}

export default Nulls;
