import React, { useEffect, useRef, useState } from "react";
import Loaders from "../Loaders";
import HeaderTop from "../HeaderTop";
import Menus from "../Menus";
import Footers from "../Footers";
import '../../../libs/css/bootstrap.min3.3.7.css';
import '../../../libs/css/sweetalert2.min.css';
import { API_ROUTES } from "../../../utils/constants";
import moment from 'moment';
import Nulls from "../Nulls";
import CPTable from "./CPTable";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";


export default function CPs(props){
    const {objectToFormData, errorFunction, Helmet, capitalizeWords, isAuthExpired, getRandomThreeDigitNumber } = props;
    useEffect(() => {document.title = "Content Providers | Forthsoft"}, []);

    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [dataTotal, setDataTotal] = useState(0);
    const [dataList, setDataList] = useState([]);
    const [columns, setColumns] = useState([]);
    const [page, setPage] = useState(1);
    const countPerPage = 20;
    const [isFade, setIsFade] = useState(false);

    const openLink = (row) => {
        // console.log(row);
        localStorage.setItem('cp_details', JSON.stringify(row));
    }

    const handleActiveDeactivate = async (id, status) => {
        console.log("sssssss");
        console.log(id);

        let urls = API_ROUTES.ACTIVATE_CP;
        if(status === "deactive"){
            urls = API_ROUTES.DEACTIVATE_CP;
        }
        const dataString = { id };

        try {
            const result = await Swal.fire({
                title: `Proceed to ${status} this CP?`,
                text: 'This cannot be undone, proceed?',
                icon: 'question',
                iconHtml: '?',
                showCancelButton: true,
                confirmButtonColor: '#337ab7',
                cancelButtonColor: '#ccc',
                confirmButtonText: `Yes, Proceed`,
            });
            if(result.isConfirmed) {
                Swal.fire({
                    title: 'Processing...',
                    text: "Please wait a second for a response...",
                    icon: 'success',
                    showConfirmButton: false,
                    confirmButtonColor: '#027937',
                    cancelButtonColor: '#d33',
                });
                const response = await fetch(urls, {
                    method: "POST",
                    body: objectToFormData(dataString),
                    headers: {'token': userData?.responseDetails?.token},
                });
                const responseJSON = await response.json();
    
                if(responseJSON.status.toString() === "200"){
                    Swal.fire({
                        title: "Successful",
                        html: `CP has been ${status}ed`,
                        icon: "success",
                        timer: 4000
                    });
                }else{
                    if(responseJSON.status.toString() === "500"){
                        Swal.fire({
                            title: "Error",
                            text: responseJSON.required_fields,
                            icon: "error",
                        });
                        return;
                    }
                    Swal.fire({
                        title: "Error",
                        text: responseJSON.message,
                        icon: "error",
                    });
                }
            }
        } catch (error) {
            if(error.response){
                errorFunction(error);
            }
        }
    }


    const columns1 = [
        {
            name: '...',
            cell: (row) => {
                let names = row.name.replace(/ /g, "-").toLowerCase();
                names = `${names}--${getRandomThreeDigitNumber()}${row.id}${getRandomThreeDigitNumber()}`;
                return (
                    <div>
                        <Link to={`/edit-cp/${names}`} onClick={() => openLink(row)} title="Click to Edit CP" className="btn"><i className="fa fa-pencil"></i></Link>
                    </div>
                )
            },
        },
        {   
            name: 'Service Name',
            selector: row => row.name ? capitalizeWords(row.name) : <Nulls />,
            sortable: true,
        },
        {
            name: 'Email',
            selector: row => row.email ? row.email : <Nulls />,
            sortable: true,
        },
        {   
            name: 'Status',
            cell: row => {
                let isStatus = <div>
                        <div style={{ marginBottom:'-2px' }}><b style={{color:'red', fontSize:'14px'}}>Deactivated</b></div>
                        <div><font style={{ fontSize:'12px', cursor: 'pointer', color:'green' }} onClick={() => handleActiveDeactivate(row.id, 'active')}>Click to activate</font></div>
                    </div>
                if(row.status === 1){
                    isStatus = <div>
                        <div style={{ marginBottom:'-2px' }}><b style={{color:'green', fontSize:'14px'}}>Active</b></div>
                        <div><font style={{ fontSize:'12px', cursor: 'pointer', color:'red' }} onClick={() => handleActiveDeactivate(row.id, 'deactive')}>Click to deactivate</font></div>
                    </div>
                }
                return isStatus;
            },
        },
        {   
            name: 'Last Login',
            selector: row => row.last_login ? moment(row.last_login).format('MMMM Do h:mma') : <Nulls />,
        },
        {   
            name: 'Registered',
            selector: row => row.created_at ? moment(row.created_at).format('MMMM Do h:mma') : <Nulls />,
        },
    ];


    useEffect(() => {
        setColumns(columns1);
    }, []);

    const userData = JSON.parse(localStorage.getItem('userDataFs'));

    const getData = async (isLoad=true, network="", keyword="", price_point="") => {
        let url = "";
        // url = `${API_ROUTES.VIEW_SERVICES}?page=${page}&perPage=${countPerPage}`;
        // if(network !== null || keyword !== null){
        //     url = `${API_ROUTES.VIEW_SERVICES}?page=${page}&perPage=${countPerPage}&network=${network}&keyword=${keyword}&price_point=${price_point}`;
        // }

        // url = `${API_ROUTES.VIEW_SERVICES}`;
        // if(network !== null || keyword !== null){
        //     url = `${API_ROUTES.VIEW_SERVICES}?network=${network}&keyword=${keyword}&price_point=${price_point}`;
        // }

        // const dataString = { 
        //     page: page,
        //     network: network,
        //     keyword: keyword,
        //     price_point: price_point,
        // };
        setIsFade(true);

        try {
            const response = await fetch(API_ROUTES.VIEW_CP, {
                method: "POST",
                headers: {'token': userData?.responseDetails?.token},
            });

            const responseJSON = await response.json();
            isAuthExpired(responseJSON); // check for expired login

            if (responseJSON.status.toString() === "200") {
                const responseData = responseJSON.data;
                
                const totalData = responseData.length;
                setDataTotal(totalData);
                setDataList(responseData);
                setIsLoading(false);
                setIsFade(false);
            }
        } catch (error) {
            console.log('Error getting data: ', error);
            setDataTotal({});
            setDataList([]);
            setIsLoading(false);
            setIsFade(false);
        }
    }

    
    useEffect(() => {
        getData(true, "", "", "");
    }, [page]);

    const handleChange1 = async (event) => {
        const filters = event.target.value;
        getData(true, "", filters, "");
    }

    const handleNtwk = async (event) => {
        const filters = event.target.value;
        getData(true, filters, "", "");
    }


    return (
        <>
            <Loaders />

            <div className="flapt-page-wrapper mainContent">
                <Menus />

                <div className="flapt-page-content">
                    <HeaderTop />
                    <div className="main-content introduction-farm mt--20">
                        <div className="content-wraper-area">
                            <div className="dashboard-area">
                                <div className="container-fluid mb-90">
                                    <div className="row g-4">
                                        <div className="col-lg-12 pl-xs-0 pr-xs-0">
                                            <div className="card-header mb--20 mb-xs-10">
                                                <h2>Content Providers</h2>
                                            </div>

                                            <CPTable capitalizeWords={capitalizeWords} dataList={dataList} dataTotal={dataTotal} isLoading={isLoading} columns={columns} countPerPage={countPerPage} setPage={setPage} handleChange1={handleChange1} isFade={isFade} handleNtwk={handleNtwk} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footers />
                    </div>
                </div>
            </div>
        </>
    );
}