import React, { useEffect, useState } from 'react';
import { API_ROUTES } from '../../../utils/constants';


const FilterComponent = (props) => {
    const { handleChange, handleNtwk, handleDropDownChange, isAuthExpired, filterDatas, register } = props;

    const [operatorList, setOperatorList] = useState([]);
    const [isLoading1, setIsLoading1] = useState(true);
    const userData = JSON.parse(localStorage.getItem('userDataFs'));

    const getOperators = async () => {
        setIsLoading1(true);
        try {
            const response = await fetch(API_ROUTES.NETWORKS, {
                method: "POST",
                headers: {'token': userData?.responseDetails?.token},
            });
            const responseJSON = await response.json();
            isAuthExpired(responseJSON);
            if (responseJSON.status.toString() === "200") {
                const responseData = responseJSON.data;
                setOperatorList(responseData);
                setIsLoading1(false);
            }
        } catch (error) {
            console.log('Error getting data: ', error);
            setOperatorList([]);
            setIsLoading1(false);
        }
    }


    useEffect(() => {
        getOperators();
    }, []);

    
    // const handleNtwk = (e) => {
    //     // console.log(e.target.value);
    //     filterDatas(e.target.value);
    // }


    return (
        <div className='row mb-10'>
            <div className='offset-xl-4 col-xl-2 offset-lg-3 col-lg-2 offset-sm-3 col-sm-2 col-6 pr-xl-5 pr-lg-5 pr-md-5 pr-sm-0 pl-xs-15 pr-xs-5'>
                <select className='filter_text' onChange={handleNtwk}>
                    <option value="">-Select Network-</option>
                    {
                        operatorList.length !== 0 && operatorList.map(operator => { 
                            return <option key={operator.id} value={operator.id}>{operator.network}</option>
                        })
                    }
                </select>
            </div>

            <div className='col-xl-2 col-xl-3 col-lg-2 col-sm-3 col-6 pl-lg-5 pr-lg-10 pl-md-5 pr-md-5 pl-sm-10 pr-sm-5 pl-xs-5 pr-xs-15'>
                <select className='filter_text1' onChange={handleDropDownChange} {...register("filter_text1")}>
                    <option value="">-Select One-</option>
                    <option value="service_id">Service ID</option>
                    <option value="service_name">Service Name</option>
                    <option value="product_name">Product Name</option>
                </select>
            </div>

            <div className='offset-xl-0 col-xl-4 offset-lg-0 col-lg-4 pl-lg-0 offset-sm-0 col-sm-4 offset-0 pl-sm-0 pl-md-5 pr-sm-20 pl-xs-15 mt-xs-5 pr-xs-15'>
                <input
                className='filter_text filter_box'
                onChange={handleChange}
                placeholder={`Filter here`}
                />
            </div>
        </div>
    );
};

export default FilterComponent;
