import React, { useEffect, useState } from "react";
import { useForm } from 'react-hook-form';
import Swal from "sweetalert2";
import '../libs/css/bootstrap.css';
import '../libs/css/style.css';
import '../libs/css/responsive.css';
import '../libs/css/custom-bootstrap-margin-padding.css';
import $ from "jquery";
import Loaders from "./Dashboards/Loaders";
import { useNavigate } from "react-router-dom";
import { API_ROUTES } from '../utils/constants';


export default function Login(props){
    useEffect(() => {document.title = "Admin Login | Forthsoft"}, []);

    const { objectToFormData } = props;
    const rememberLogin = localStorage.getItem('rememberLoginFs');
    const rememberMe = rememberLogin ? true : false;
    const userInput = localStorage.getItem('userInputFs');
    const inputObject = JSON.parse(userInput);
    const isEmail = inputObject ? atob(inputObject.email) : '';
    const isPassword = inputObject ? atob(inputObject.password) : '';


    const { register, handleSubmit, formState: {errors, isSubmitting, isValid} } = useForm({
        mode: "onChange",
        defaultValues: {
            email: rememberMe ? isEmail : '',
            password: rememberMe ? isPassword : '',
            remember: rememberMe
        }
    });

    const [showLogin, setShowLogin] = useState(true);
    const [showReset, setShowReset] = useState(false);
    const [isChecked, setIsChecked] = useState(rememberMe);
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();


    const userData = localStorage.getItem('userDataFs');
    const userObj = JSON.parse(userData);
    const isAuthenticated = { 'isLoggedIn': userObj !== null ? userObj.isLoggedIn : false }

    if(isAuthenticated.isLoggedIn){
        navigate("/dashboard");
    }

    const forgotPassLink = () => {
        setShowLogin(!showLogin);
        setShowReset(!showReset);
    }

    const loginLink = () => {
        setShowLogin(!showLogin);
        setShowReset(!showReset);
    }

    const togglePassword = () => {
        setShowPassword(!showPassword);
    }


    const loginAuth = async (data) => {
        // console.log(data);
        // return;
        try {
            const response = await fetch(API_ROUTES.SIGN_IN, {
                method: "POST",
                body: objectToFormData(data),
            });
            const responseJSON = await response.json();
            const message = responseJSON.message;

            if(responseJSON.status.toString() === "200"){
                const responseDetails = responseJSON.data;

                const userData = {};
                userData.isLoggedIn = true;
                userData.responseDetails = responseDetails;

                global.tokens = userData?.responseDetails?.token;

                if(responseDetails.twoFa_status === 1){ // is2FA
                    const response1 = await fetch(API_ROUTES.TWO_FA_TOKEN, {
                        method: 'POST',
                        body: objectToFormData(data),
                        headers: {'token': userData?.responseDetails?.token},
                    });
                    const responseJSON2 = await response1.json();
                    // console.log(responseJSON2);

                    const result = await Swal.fire({
                        title: '2 Factor Authentication! Enter the code sent to your email and continue',
                        html: `<div class="text-left swal_inputs swal_inputs_pass">
                                <input type="number" id="ccode" class="swal2-input" placeholder="Enter code">
                            </div>`,
                        confirmButtonText: 'Continue',
                        icon: 'question',
                        iconHtml: '?',
                        focusConfirm: false,
                        showCancelButton: true,
                        cancelButtonColor: '#999',
                        preConfirm: () => {
                            const ccode = Swal.getPopup().querySelector('#ccode').value
                            if (!ccode) {
                            Swal.showValidationMessage(`Please enter code sent to your email`);
                            return;
                            }
                            return { ccode: ccode }
                        }
                    })
                    if (result.isConfirmed) {
                        Swal.fire({
                            title: 'Processing...',
                            text: "Please wait a second for a response...",
                            icon: 'success',
                            showConfirmButton: false,
                            confirmButtonColor: '#027937',
                            cancelButtonColor: '#d33',
                        });
                        try {
                            const dataString = { 
                                twoFatoken: result.value.ccode,
                                email: userData?.responseDetails?.email,
                            };
                            const response = await fetch(API_ROUTES.TWO_FA_CHECK, {
                                method: 'POST',
                                body: objectToFormData(dataString),
                                headers: {'token': userData?.responseDetails?.token},
                            });
                            const responseJSON = await response.json();
                            if (responseJSON.status === 200) {
                                localStorage.setItem('userDataFs', JSON.stringify(userData));
                                const userInput = {};
                                userInput.email = btoa(data.email);
                                userInput.password = btoa(data.password);

                                if(isChecked){
                                    localStorage.setItem('userInputFs', JSON.stringify(userInput));
                                }else{
                                    localStorage.removeItem('userInputFs');
                                }
                                localStorage.setItem('rememberLoginFs', data.remember);

                                Swal.fire({
                                    title: 'Successful',
                                    html: "",
                                    icon: 'success',
                                    timer: 1000,
                                });
                                navigate("/dashboard");
                                return;
                            } else {
                                Swal.fire({
                                    title: 'Error',
                                    html: responseJSON.message || 'An error occurred during the POST request.',
                                    icon: 'error',
                                });
                                return;
                            }
                        } catch (error) {
                            Swal.fire({
                                title: 'Error',
                                text: error.message || 'An error occurred during the POST request.',
                                icon: 'error',
                            });
                            return;
                        }
                    };
                    
                }else{
                    localStorage.setItem('userDataFs', JSON.stringify(userData));
                    const userInput = {};
                    userInput.email = btoa(data.email);
                    userInput.password = btoa(data.password);

                    if(isChecked){
                        localStorage.setItem('userInputFs', JSON.stringify(userInput))
                    }else{
                        localStorage.removeItem('userInputFs');
                    }
                    localStorage.setItem('rememberLoginFs', data.remember);
                    navigate("/dashboard");
                }

                
            }else{
                Swal.fire({
                    title: "<font className='error_title'>Error</font>",
                    html: `${message}`,
                    icon: "error"
                });
            }
        } catch (error) {
            Swal.fire({
                icon:   "error",
                html:   error,
                title:  "Error",
            });
        }
    }

    return (
        <div className="container-fluid_">

            <Loaders />

            <div className="row mt-xs--10">
                
                <div className="col-xl-6 d-xl-block d-none"><img className="bg-img-cover bg-center" src={require('../images/login.jpg')} alt="looginpage" /></div>

                <div className="col-xl-6 p-0">
                    <div className="login-card login-dark pl-xs-90 pr-xs-90">
                        <div>
                            <div className="text-center_">
                                <a className="logo text-center" href="">
                                    <img className="img-fluids for-light" src={require('../images/logo2-black.png')} alt="logo" />
                                    <span className="ml-10" style={{ fontSize:15, fontWeight:'600', color:'#fff' }}>Admin</span>
                                </a>
                            </div>
                            {
                                showLogin &&
                                <div className="login-main mt--10 mt-xs-20"> 
                                    <form className="theme-form" onSubmit={handleSubmit(loginAuth)}>
                                        <h4>Sign in to Your Admin Account</h4>
                                        <p style={{ fontSize:'15px' }}>Enter your login credentials</p>
                                        <div className="form-group mt-xs-20">
                                            <label className="col-form-label">Email Address</label>
                                            {errors.email && <label className="errors">{errors.email?.message}</label>}

                                            <input className="form-control" type="email" name="email" required="" placeholder="Enter email or phone" {...register("email", {
                                                required: "Your email or phone is required"
                                            })} />
                                        </div>

                                        <div className="form-group mt-15">
                                            <label className="col-form-label">Password</label>
                                            {errors.password && <label className="errors">{errors.password?.message}</label>}

                                            <div className="form-input position-relative">
                                                <input 
                                                className="form-control" 
                                                type={showPassword ? 'text' : 'password'} 
                                                name="password"
                                                placeholder="Enter password" 
                                                defaultValue={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                                {...register("password", {required: "Your password is required"})} />
                                                <div className="show-hide" onClick={togglePassword}>{showPassword ? 'hide' : 'show'}</div>
                                            </div>
                                        </div>

                                        <div className="form-group mt-15 mb-10">
                                            <div className="checkbox p-0">
                                                <input id="checkbox1" type="checkbox" name="remember" />
                                                    <label className="text-muted" htmlFor="checkbox1" {...register('remember')} checked={isChecked} onChange={() => setIsChecked(!isChecked)}>Remember password</label>
                                            </div>

                                            <a className="link" href="#" onClick={forgotPassLink}>Forgot password?</a>

                                            <div className="text-end mt-15">
                                                <button className="btn btn-primary btn-block w-100" type="submit" disabled={isSubmitting || !isValid} style={{ opacity: isSubmitting ? '0.5' : 1 }}>{isSubmitting ? 'Signing In...' : 'Sign in'}</button>
                                            </div>
                                        </div>        
                                        {/* <p className="mt-4 mb-0 text-center">Don't have account?<a className="ms-2" href="sign-up.html">Create Account</a></p> */}
                                    </form>
                                </div>
                            }

                            {
                                showReset &&
                                <div className="login-main mt--10 mt-xs-20"> 
                                    <form className="theme-form">
                                        <h4>Reset Your Password</h4>
                                        <p style={{ fontSize:'15px' }}>Enter your email associated with Forthsoft</p>
                                        <div className="form-group mt-xs-20">
                                            <label className="col-form-label">Email Address</label>
                                            <input className="form-control" type="email" required="" placeholder="Enter your email address" />
                                        </div>
                                        <div className="form-group mt-30 mb-10">
                                            <div className="text-end mt-15">
                                                <button className="btn btn-primary login_btn btn-block w-100" type="submit">Reset Password</button>
                                            </div>
                                        </div>
                                        <div className="text-center">
                                            <a href="#" onClick={loginLink}>Go back</a>
                                        </div>
                                                            
                                        {/* <p className="mt-4 mb-0 text-center">Don't have account?<a className="ms-2" href="sign-up.html">Create Account</a></p> */}
                                    </form>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}