import React from 'react';


const FilterComponent = (props) => {
    const { handleChange, handleNtwk } = props;

    return (
        <div className='row mb-10'>
            <div className='offset-lg-8 col-lg-4 pl-lg-0 offset-sm-6 col-sm-6 offset-4 col-8 pr-sm-20 pl-xs-5 pr-xs-15'>
                <input
                className='filter_text'
                onChange={handleChange}
                placeholder={`Filter here`}
                />
            </div>
        </div>
    );
};

export default FilterComponent;
