import React from 'react';
import { useForm } from "react-hook-form";


const FilterComponent = (props) => {
    const { handleDuration, allCPs } = props;
    const { register: register1, handleSubmit: handleSubmit1, formState: {errors: errors1, isSubmitting: isSubmitting1, isValid: isValid1} } = useForm({mode: 'onChange'});

    

    return (
        <div className='row mb-10 mt-40'>
            <form onSubmit={handleSubmit1(handleDuration)} className='row'>
                <div className='col-lg-12'>
                    <div className='col-sm-4 pr-0'>
                        <select className='filter_text' {...register1("cp_id")} style={{ textTransform:'capitalize' }}>
                            <option value="0|">-Select CP-</option>
                            {
                                allCPs.length !== 0 && allCPs.map(mycp => { 
                                    return <option key={mycp.id} value={`${mycp.id}|${mycp.name}`}>{mycp.name}</option>
                                })
                            }
                        </select>
                    </div>

                    <div className='col-sm-8 pl-5 pr-15 mt-xs-5 pl-xs-20'>
                        <div className='col-sm-5 col-5 pl-5 pr-0'>
                            <input type="date" className='filter_text' placeholder={`Filter here`} {...register1('fro')} defaultValue={new Date().toISOString().substr(0, 10)}  />
                        </div>
                        <div className='col-sm-5 col-5 pl-5 pr-0'>
                            <input type="date" className='filter_text' placeholder={`Filter here`} {...register1('to')} defaultValue={new Date().toISOString().substr(0, 10)}  />
                        </div>
                        <div className='col-sm-2 col-2 col-sm-2 p-0'>
                            <button className="btn_1 btn-primary" type="submit" disabled={isSubmitting1 || !isValid1} style={{ opacity: isSubmitting1 ? '0.5' : 1 }}><i className='fa fa-search'></i></button>
                        </div>
                    </div>

                </div>
            </form>
        </div>
    );
};

export default FilterComponent;
