import React, { useEffect, useState } from "react";

export default function Loaders(){
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const hasLoaderBeenShown = localStorage.getItem('loaderShown');
        if (hasLoaderBeenShown) {
          setIsLoading(false); 
        } else {
          setTimeout(() => {
            setIsLoading(false);
            localStorage.setItem('loaderShown', 'true');
          }, 600);
        }
    }, []);

    const preloaderStyles = {
        opacity: isLoading ? 1 : 0,
        transition: 'opacity 0.5s',
    };

    return (
        <>
            { isLoading && 
                <div className="loader-wrapper" id="preloader_" style={preloaderStyles}> 
                    <div className="loader loader-1">
                        <div className="loader-outter"></div>
                        <div className="loader-inner"></div>
                        <div className="loader-inner-1"></div>
                    </div>
                </div>
            }
        </>
    );
}