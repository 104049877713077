import { useState } from "react";
import { API_ROUTES } from "../utils/constants";

const userData = JSON.parse(localStorage.getItem('userDataFs'));
const token = userData?.token || '';
const headers = { 'Authorization': `Bearer ${token}` }
const headers2 = { 'token': `${token}` }

const headers1 = {
  'Content-Type': 'application/json',
  'Authorization': `Bearer ${token}`
};

const sys_settings = async () => {
    try {
      const response = await fetch(API_ROUTES.SETTINGS, {headers});
      if(!response.ok){
          throw new Error(`This is a URL error: Status is ${response.status}`);
      }
      const readData = await response.json();
      const responseJSON = atob(readData.data);
      return JSON.parse(responseJSON);
    } catch (error) {
      return error.message;
    }
}

export { sys_settings, headers, headers1, headers2 };