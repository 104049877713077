import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import '../../../libs/css/dataTable.css';
import Nulls from '../Nulls';
import FilterComponent from './FilterComponent';
import { format } from 'date-fns';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';


function ServicesTable(props) {
    const { capitalizeWords, isLoading, columns, dataTotal, dataList, countPerPage, setPage, handleChange1, isFade, handleNtwk, handleDropDownChange, fadingRows, isAuthExpired, register } = props;

    const ExpandedComponent = ({ data }) => (
        <div className='text-left th_data'>
            <div><font>Product ID:</font> {data.productId ? data.productId : <Nulls />}</div>
            
            <div><font>Service Name:</font> {data.serviceName ? data.serviceName : <Nulls />}</div>
            <div><font>Product Name:</font> {data.product_name ? data.product_name : <Nulls />}</div>

            <div><font>Product Code:</font> {data.productCode ? data.productCode : <Nulls />}</div>
            <div><font>Keyword:</font> <span style={{ textTransform: 'uppercase' }}>{data.keyword}</span></div>
            <div><font>Date Created:</font> {data.created_at ? format(new Date(data.created_at), 'MMM d, yyyy h:mma') : <Nulls />}</div>
        </div>
    );

    
    // const [toggledClearRows, setToggledClearRows] = React.useState(false);
      
    // const handleClearRows = () => {
    //     setToggledClearRows(!toggledClearRows);
    // }



  return (
    <div className="App">

        <FilterComponent handleChange={handleChange1} handleNtwk={handleNtwk} handleDropDownChange={handleDropDownChange} isAuthExpired={isAuthExpired} register={register} />

        <div style={{ opacity: isFade ? '0.3' : 1 }} className=''>
            {
                isFade ? 
                <LoadingSpinner /> 
                :
                <DataTable
                    className="services_datatable users"
                    title=""
                    expandableRows
                    expandableRowsComponent={ExpandedComponent}
                    columns={columns}
                    data={dataList.map((row) => ({
                        ...row,
                        className: fadingRows.includes(row.id) ? 'fade-out' : '',
                    }))}
                    highlightOnHover
                    pagination
                    paginationServer
                    paginationTotalRows={dataTotal}
                    paginationPerPage={countPerPage}
                    paginationComponentOptions={{
                    noRowsPerPage: true
                    }}
                    onChangePage={page => setPage(page)}
                />

            }
        </div>
            
        
    </div>
  );
}

export default ServicesTable;
