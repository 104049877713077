import React from "react";
import { Link, useNavigate } from "react-router-dom";

export default function HeaderTop(){
    const navigate = useNavigate();

    const capitalizeWords = (string) => {
        const words = string.split(' ');
        const capitalizedWords = words.map((word) => {
          return word.charAt(0).toUpperCase() + word.slice(1);
        });
        return capitalizedWords.join(' ');
    }
    const userData = JSON.parse(localStorage.getItem('userDataFs'));
    const user_name = userData?.responseDetails?.name.split(' ')[0];


    const logout = () => {
        localStorage.removeItem('userDataFs');
        navigate('/login');
    }
    
    return (
        <header className="top-header-area d-flex align-items-center justify-content-between">
            <div className="left-side-content-area d-flex align-items-center">
                <div className="mobile-logo">
                    <a href="#"><img src="../assets/images/small-logo.png" alt="Mobile Logo" /></a>
                </div>

                <div className="flapt-triggers">
                    <div className="menu-collasped" id="menuCollasped">
                        <i className="bx bx-grid-alt"></i>
                    </div>
                    <div className="mobile-menu-open" id="mobileMenuOpen">
                        <i className="bx bx-grid-alt"></i>
                    </div>
                </div>
            </div>

            <div className="right-side-navbar d-flex align-items-center justify-content-end">
                <div className="right-side-trigger" id="rightSideTrigger">
                    <i className="bx bx-menu-alt-right"></i>
                </div>

                <ul className="right-side-content d-flex align-items-center">
                    <li className="nav-item dropdown">
                        <button type="button" className="btn dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><img src="../assets/images/avatar.png" alt="" /></button>
                        <div className="dropdown-menu profile dropdown-menu-right">
                            <div className="user-profile-area">
                                <Link to="/settings" className="dropdown-item"><i className="bx bx-wrench font-15" aria-hidden="true"></i> My Settings</Link>

                                <Link onClick={logout} to="/" className="dropdown-item"><i className="bx bx-power-off font-15" aria-hidden="true" style={{ fontSize:'10px' }}></i> Sign-out</Link>
                            </div>
                        </div>
                    </li>

                    <li className="nav-item dropdown">
                        {capitalizeWords(user_name)}
                    </li>
                </ul>
            </div>
        </header>

    );
}