import React from "react";

export default function Footers(){
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-12 text-center">
                    <footer className="footer-area _d-sm-flex justify-content-center align-items-center justify-content-between">
                        <div className="text-center mt-15">
                            <p className="mb-0 f-w-600">Copyright 2023 © Forthsoft</p>
                        </div>
                    </footer>
                </div>
            </div>
        </div>
    );
}